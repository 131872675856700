import classnames from 'classnames/bind'
import React from 'react'

import { ReactComponent as Arrow } from './arrow-gold.svg'
import css from './styles.module.scss'

const cx = classnames.bind(css)

interface SliderArrowProps {
  className?: string
  onClick?: () => void
  left?: boolean
  disabled?: boolean
}

export default function SliderArrow({
  className,
  onClick,
  disabled,
  left,
}: SliderArrowProps) {
  return (
    <div
      className={cx(className, css.Arrow, { left, disabled })}
      onClick={onClick}>
      <Arrow className={cx(css.icon, { disabled })} />
    </div>
  )
}
