import { RefObject } from 'react'

import { useMeasure } from '@unlikelystudio/react-hooks'

function useStickyContent(
  contentRef?: RefObject<any>,
  imageRef?: RefObject<any>,
) {
  const { height: textHeight } = useMeasure(contentRef)
  const { height: imageHeight } = useMeasure(imageRef)
  const headerHeight = 60

  const processTopValue = (value: number) =>
    `calc(50% - ${value / 2 - headerHeight}px)`

  return {
    imageTop: processTopValue(imageHeight),
    contentTop: processTopValue(textHeight),
  }
}

export default useStickyContent
