import classnames from 'classnames/bind'
import React, {
  ButtonHTMLAttributes,
  DOMAttributes,
  ReactNode,
  useRef,
} from 'react'
import { UrlObject } from 'url'

import { useIsHover } from '@unlikelystudio/react-hooks'

import Link, { LinkProps } from '~/components/Link'
import Spinner from '~/components/Spinner'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export enum ButtonThemes {
  Dark = 'dark',
  Light = 'light',
  Transparent = 'transparent',
}
interface BaseProps extends DOMAttributes<any> {
  className?: string
  fullWidth?: boolean
  ctaAndChildrenClassName?: string
  type?: ButtonHTMLAttributes<any>['type']
  href?: string | UrlObject
  form?: string
  children?: ReactNode
  theme?: ButtonThemes
  isLoading?: boolean
  withBackground?: boolean
  withBorder?: boolean
  withHover?: boolean
  disabled?: boolean
  isExternal?: boolean
}

export type SquaredCtaProps = BaseProps | (LinkProps & BaseProps)

function textColorFromTheme(
  theme: BaseProps['theme'],
  withBackground: BaseProps['withBackground'],
): BaseProps['theme'] {
  if (withBackground) {
    return theme !== ButtonThemes.Dark ? ButtonThemes.Dark : ButtonThemes.Light
  } else {
    return theme === ButtonThemes.Transparent
      ? ButtonThemes.Light
      : ButtonThemes.Dark
  }
}

function SquaredCta({
  className,
  ctaAndChildrenClassName,
  children,
  href,
  fullWidth,
  withBackground,
  theme,
  type,
  onMouseEnter,
  onMouseLeave,
  withBorder,
  withHover,
  isLoading,
  ...rest
}: SquaredCtaProps) {
  const { isExternal, ...props } = rest ?? {}

  const [isHover, mouseEvents] = useIsHover({
    onMouseEnter,
    onMouseLeave,
  })

  const childrenTextRef = useRef<HTMLDivElement>()

  const ctaTextStyle = useStyle({
    textPreset: 'cta-10',
    color: textColorFromTheme(theme, withBackground),
  })

  const ctaMaskTextStyle = useStyle({
    textPreset: 'cta-10',
    color: textColorFromTheme(theme, !withBackground),
  })

  const classNames = cx(
    className,
    css.SquaredCta,
    isHover ? ctaMaskTextStyle : ctaTextStyle,
    ctaAndChildrenClassName,
    {
      fullWidth,
      withBackground,
      withBorder,
      hovered: isHover,
      loading: isLoading,
      [`${theme}Theme`]: theme,
    },
  )

  const squaredCtaChildren = (
    <div className={css.childrenContainer}>
      {isLoading && (
        <div className={cx(css.spinner)}>
          <Spinner color={theme === ButtonThemes.Dark ? 'white' : 'black'} />
        </div>
      )}
      <div className={cx(css.childrenContent, { isLoading })}>
        <div ref={childrenTextRef}>{children}</div>
      </div>
    </div>
  )

  return href ? (
    <Link href={href} className={classNames} {...mouseEvents} {...rest}>
      {squaredCtaChildren}
    </Link>
  ) : (
    <button type={type} className={classNames} {...mouseEvents} {...props}>
      {squaredCtaChildren}
    </button>
  )
}

SquaredCta.defaultProps = {
  theme: ButtonThemes.Transparent,
  withBackground: true,
  withBorder: true,
  withHover: true,
}

export default SquaredCta
