import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'

import { randomIntFromInterval } from '~/utils/maths/random'

import { CookieProps } from './context'

// Timestamp 24h
const DAY_TIMESTAMP = 1000 * 60 * 60 * 24

export interface ProductItem {
  id: string
  type: string
  date: number
}

export type Lang = ProductItem[]

export function getRecentlyViewedProducts(ctx: CookieProps) {
  const { cookieKey } = ctx
  const cookie = Cookies.get(cookieKey)

  return cookie ? JSON.parse(cookie) : {}
}

export function getRecentlyViewedProductsFromLocale(
  locale: string,
  ctx: CookieProps,
) {
  return getRecentlyViewedProducts(ctx)[locale]
}

/**
 * Add DB new Item
 * @param uid
 * @param locale
 * @param DB
 */
export function addId(id: string, type: string, locale: string, DB: Lang[]) {
  const item: ProductItem = {
    id,
    type,
    date: Date.now(),
  }

  DB[locale].push(item)

  return DB
}

/**
 * Remove DB Item
 * @param id
 * @param locale
 * @param DB
 */
export function removeUID(id: string, locale: string, DB: Lang[]) {
  DB[locale] = DB[locale].filter((n: ProductItem) => n.id !== id)
  return DB
}

/**
 * Update DB Existing Item date
 * @param uid
 * @param locale
 * @param DB
 */
export function updateId(id: string, locale: string, DB: Lang[]) {
  const now = Date.now()
  const index = DB[locale].findIndex((n: ProductItem) => n.id === id)
  DB[locale][index].date = now
}

export function addProductViewed(
  id: string,
  type: string,
  locale: string,
  ctx: CookieProps,
) {
  const { expireDays, maxProducts, cookieAge, cookieKey } = ctx
  const DB = getRecentlyViewedProducts(ctx)

  if (!DB[locale]) DB[locale] = []

  const productAlreadyViewed = Boolean(
    DB[locale].find((n: ProductItem) => n.id === id),
  )

  if (!productAlreadyViewed) addId(id, type, locale, DB)
  else updateId(id, locale, DB)

  // Filter too old items and delete it
  for (const [key, loc] of Object.entries<Lang>(DB)) {
    DB[key] = loc.filter((n) => {
      return Date.now() < n.date + expireDays * DAY_TIMESTAMP
    })
  }

  // Reorder
  for (const [, loc] of Object.entries<Lang>(DB)) {
    loc.sort(function (a, b) {
      return b.date - a.date
    })
  }

  // Delete older items if > MAX_PRODUCTS_RECENTLY_VIEWED
  for (const [, loc] of Object.entries<Lang>(DB)) {
    if (loc.length > maxProducts) {
      DB[locale] = loc.slice(0, maxProducts)
    }
  }

  Cookies.set(cookieKey, JSON.stringify(DB), {
    expires: cookieAge,
  })
}

// Fake UID set of datas
const RANDOMS_IDS = ['YKeraBEAACEAcR6y', 'YHWN2hMAACIAp14V']

// Fake LOCALES
const LOCALES = ['fr-fr', 'en-us']

/**
 * Debug function to add random UID when traveling on website
 * @param ctx
 */
export function addRandomUID(ctx: CookieProps) {
  const ID = RANDOMS_IDS[randomIntFromInterval(0, RANDOMS_IDS.length - 1)]
  const locale = LOCALES[randomIntFromInterval(0, LOCALES.length - 1)]
  addProductViewed(ID, 'product', locale, ctx)
}

export default function useProductsRecentlyViewed(
  locale: string,
  ctx: CookieProps,
) {
  const [products, setProducts] = useState<ProductItem[]>([])

  useEffect(() => {
    if (locale && ctx) {
      const products = getRecentlyViewedProductsFromLocale(locale, ctx)
      if (products) setProducts(products)
    }
  }, [locale, ctx])

  return products
}
