import classnames from 'classnames/bind'
import React from 'react'

import ProductCard from '~/components/ProductCard'
import { SerializedProductProps } from '~/components/ProductCard/types'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface ProductsGridProps {
  className?: string
  products?: SerializedProductProps[]
  tag?: 'section' | 'div'
  aboveTheFold?: (index: number) => boolean
}

function ProductsGrid({
  className,
  products,
  tag,
  aboveTheFold,
}: ProductsGridProps) {
  const gridStyle = useStyle({ grid: 'base-grid' })
  const Wrapper = tag

  return (
    <Wrapper className={cx(className, gridStyle, css.ProductsGrid)}>
      <div className={css.productsContainer}>
        {products
          ?.filter((product) => product?.variants?.length > 0)
          ?.map((product, index) => {
            const priority = aboveTheFold?.(index) ?? false

            return (
              <ProductCard
                key={`${product?.variationId ?? product?.id}_${index}`}
                {...product}
                className={css.product}
                priority={priority}
              />
            )
          })}
      </div>
    </Wrapper>
  )
}

ProductsGrid.defaultProps = {
  tag: 'section',
}

export default ProductsGrid
