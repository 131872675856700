import classnames from 'classnames/bind'
import React, { useRef } from 'react'

import Slider, { useSliderState } from '@unlikelystudio/react-slider'

import ImageWithPlaceholder, {
  ImageWithPlaceholderProps,
} from '~/components/ImageWithPlaceholder'
import InlineCta, { InlineCtaProps } from '~/components/InlineCta'
import ProductCard from '~/components/ProductCard'
import { ProductCardProps } from '~/components/ProductCard/types'
import Ratio from '~/components/Ratio'
import RichText, { RichTextBlock } from '~/components/RichText'
import SliderArrow from '~/components/SliderArrow'
import SliderControls from '~/components/SliderControls'
import WrapperWithLink from '~/components/WrapperWithLink'

import { useStyle } from '~/providers/StyleProvider'

import getAriaLabelProp from '~/utils/get-aria-label-prop'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface ProductDypticSliderProps {
  className?: string
  inversed?: boolean
  title?: RichTextBlock[]
  coverImage?: ImageWithPlaceholderProps
  link?: InlineCtaProps
  products?: ProductCardProps[]
}

const DEFAULT_ALT = 'Product dyptic slider image'

function ProductDypticSlider({
  className,
  inversed,
  title,
  coverImage,
  link,
  products,
}: ProductDypticSliderProps) {
  const gridStyle = useStyle({ grid: 'base-grid' })
  const titleStyle = useStyle({ textPreset: 'title-20-30' })
  const sliderWrapperRef = useRef(null)
  const [{ slideIndex, setSlideIndex }, setSliderState] = useSliderState()
  const { href } = link ?? {}

  return (
    <section
      className={cx(className, css.ProductDypticSlider, gridStyle, {
        inversed,
      })}>
      <div className={css.coverWrapper}>
        {coverImage && (
          <WrapperWithLink
            className={css.wrapperWithLink}
            {...getAriaLabelProp(link?.children as string)}
            href={href as string}>
            <Ratio ratio={705 / 860}>
              {() => (
                <ImageWithPlaceholder
                  objectFit="cover"
                  layout="fill"
                  sizesFromBreakpoints={[
                    { breakpoint: 'md', columns: 6 },
                    { columns: 6 },
                  ]}
                  {...coverImage}
                  alt={coverImage?.alt ?? DEFAULT_ALT}
                />
              )}
            </Ratio>
          </WrapperWithLink>
        )}
        {(title || link) && (
          <div className={css.content}>
            {title && <RichText render={title} className={titleStyle} />}
            {link && <InlineCta {...link} />}
          </div>
        )}
      </div>
      {products && (
        <div className={css.sliderWrapper}>
          <SliderArrow
            className={cx(css.arrow, css.arrowLeft)}
            left
            onClick={() =>
              setSlideIndex((prevState) =>
                prevState <= 0 ? products?.length - 1 : prevState - 1,
              )
            }
          />

          <div className={css.sliderWidth} ref={sliderWrapperRef}>
            <Slider
              className={css.slider}
              onSlideIndexChange={(index) => setSlideIndex(index)}
              customSliderRef={sliderWrapperRef}
              setSliderState={setSliderState}
              infinite
              snap>
              {products?.map((product, index) => (
                <ProductCard
                  {...product}
                  className={css.product}
                  key={index}
                  animated
                  visible={slideIndex === index}
                />
              ))}
            </Slider>
          </div>
          <SliderArrow
            className={cx(css.arrow, css.arrowRight)}
            onClick={() =>
              setSlideIndex((prevState) =>
                prevState >= products?.length - 1 ? 0 : prevState + 1,
              )
            }
          />

          <div className={css.controlsWrapper}>
            <SliderControls
              className={css.controls}
              length={products?.length}
              slideIndex={slideIndex}
              setSlideIndex={setSlideIndex}
            />
          </div>
        </div>
      )}
    </section>
  )
}

ProductDypticSlider.defaultProps = {}

export default ProductDypticSlider
