import classnames from 'classnames/bind'
import { useRouter } from 'next/router'
import { useContext, useEffect, useState } from 'react'
import { useTranslate } from 'react-polyglot'

import Slider, { useSliderState } from '@unlikelystudio/react-slider'

import InlineCta, { InlineCtaProps } from '~/components/InlineCta'
import ProductCard from '~/components/ProductCard'
import ProductsGrid from '~/components/ProductsGrid'
import Ratio from '~/components/Ratio'
import RichText from '~/components/RichText'
import SliderArrow from '~/components/SliderArrow'
import Spinner from '~/components/Spinner'
import SquaredCta from '~/components/SquaredCta'

import { useGlobalData } from '~/providers/GlobalDataProvider'
import { useStyle } from '~/providers/StyleProvider'

import useGetProducts from '~/hooks/useGetProducts'
import useLocale from '~/hooks/useLocale'
import useProductsRecentlyViewed from '~/hooks/useProductsRecentlyViewed'
import { ProductsViewedContext } from '~/hooks/useProductsRecentlyViewed/context'

import css from './styles.module.scss'
import {
  ProductSliderComponentProps,
  ProductsSliderProps,
  SliderOptions,
} from './types'

const cx = classnames.bind(css)

const RECENTLY_VIEWED_KEY = 'recently_viewed'
const DEFAULT_PRODUCTS_AMOUNT = 4

function RecentlyViewedWrapper({ recentlyViewedProducts = [], ...props }) {
  const ids = recentlyViewedProducts?.map((item) => item?.id)

  const { data, isLoading } = useGetProducts(['products_recently_viewed'], ids)

  const products = data?.products ?? []

  return isLoading ? (
    <Ratio className={css.spinner} ratio={315 / 420}>
      {(cn) => <Spinner className={cn} />}
    </Ratio>
  ) : (
    products?.length > 0 && (
      <ProductSliderComponent {...props} products={products} />
    )
  )
}

function ProductSliderComponent({
  button,
  products,
  link,
  sliderOptions,
  setSliderState,
  loadMore,
  setSlideIndex,
}: ProductSliderComponentProps) {
  const t = useTranslate()
  const gridStyle = useStyle({ grid: 'base-grid' })
  const productsToDisplay = products?.slice(0, sliderOptions.productsAmount)
  const defaultButton: InlineCtaProps = button
    ? button
    : { children: t('cta.display_more'), theme: 'black' }
  const canLoadMore = sliderOptions.productsAmount < products?.length

  return (
    <>
      <div className={css.gridWrapper}>
        <ProductsGrid
          products={productsToDisplay}
          className={css.productsGrid}
          tag="div"
        />
        {canLoadMore && (
          <InlineCta
            {...defaultButton}
            className={css.button}
            onClick={loadMore}
          />
        )}
      </div>
      <div className={cx(css.sliderWrapper, gridStyle)}>
        {products?.length > 0 && (
          <Slider
            className={css.slider}
            onSlideIndexChange={(index) => setSlideIndex(index)}
            setSliderState={setSliderState}
            snap>
            {products?.map((product, index) => (
              <ProductCard
                key={`product${index}`}
                className={css.item}
                {...product}
              />
            ))}
          </Slider>
        )}
      </div>
      {link && (
        <SquaredCta withBorder withBackground {...link} className={css.link} />
      )}
    </>
  )
}
function ProductsSlider({
  productsLists,
  button,
  className,
}: ProductsSliderProps) {
  const locale = useLocale()
  const [{ slideIndex, setSlideIndex }, setSliderState] = useSliderState()
  const [sliderOptions, setSliderOptions] = useState<SliderOptions>({
    listIndex: 0,
    productsAmount: DEFAULT_PRODUCTS_AMOUNT,
  })
  const [list, setList] = useState(productsLists?.[sliderOptions.listIndex])

  const ProductViewedCtx = useContext(ProductsViewedContext)
  const { pageId } = useGlobalData()

  const recentlyViewedProducts = useProductsRecentlyViewed(
    locale,
    ProductViewedCtx,
  ).filter((item) => item?.id !== pageId)

  const hasViewedEnoughProducts = recentlyViewedProducts?.length > 0

  const gridStyle = useStyle({ grid: 'base-grid' })
  const textStyle = useStyle({ textPreset: 'title-10', color: 'gold' })

  const { products, link } = list ?? {}
  const slidesAmount = products?.length - DEFAULT_PRODUCTS_AMOUNT

  const changeList = (listIndex: number) => {
    setSliderOptions({
      listIndex,
      productsAmount: DEFAULT_PRODUCTS_AMOUNT,
    })
  }

  useEffect(
    () => setList(productsLists?.[sliderOptions.listIndex]),
    [sliderOptions.listIndex],
  )

  const loadMore = () =>
    setSliderOptions((prevState) => ({
      ...prevState,
      productsAmount: prevState.productsAmount + DEFAULT_PRODUCTS_AMOUNT,
    }))

  const componentProps = {
    button,
    products,
    link,
    setSliderState,
    sliderOptions,
    loadMore,
    setSlideIndex,
  }

  const isFirstSlide = slideIndex <= 0
  const isLastSlide = slideIndex >= slidesAmount
  const hasOnlyOneSlide = isFirstSlide && isLastSlide

  return (
    <section className={cx(className, css.ProductsSlider)}>
      <div className={gridStyle}>
        <div className={css.header}>
          <div className={css.titles}>
            {productsLists?.map((list, index) => {
              if (
                list?.id === RECENTLY_VIEWED_KEY &&
                !hasViewedEnoughProducts
              ) {
                return null
              }

              return (
                <div
                  key={`productList${index}`}
                  className={cx(
                    css.title,
                    index === sliderOptions.listIndex ? css.selected : '',
                  )}
                  onClick={() => changeList(index)}>
                  <RichText
                    render={list?.title}
                    className={textStyle}
                    key={index}
                  />
                </div>
              )
            })}
          </div>
          {!hasOnlyOneSlide && (
            <div className={css.sliderControls}>
              <SliderArrow
                left
                disabled={isFirstSlide}
                onClick={() => {
                  if (slideIndex > 0) setSlideIndex(slideIndex - 1)
                }}
              />
              <SliderArrow
                className={cx(css.arrowRight)}
                disabled={isLastSlide}
                onClick={() => {
                  if (slideIndex < slidesAmount) setSlideIndex(slideIndex + 1)
                }}
              />
            </div>
          )}
        </div>
      </div>

      {list?.id === RECENTLY_VIEWED_KEY && hasViewedEnoughProducts ? (
        <RecentlyViewedWrapper
          recentlyViewedProducts={recentlyViewedProducts}
          {...componentProps}
        />
      ) : (
        <ProductSliderComponent {...componentProps} />
      )}
    </section>
  )
}

ProductsSlider.defaultProps = {}

export default ProductsSlider
