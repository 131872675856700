import React, { Fragment, HTMLAttributes, ReactNode } from 'react'

import Link from '~/components/Link'

export interface WrapperWithLinkProps
  extends HTMLAttributes<HTMLAnchorElement> {
  className?: string
  children: ReactNode | ReactNode[]
  href?: string
}

function WrapperWithLink({
  className,
  children,
  href,
  ...rest
}: WrapperWithLinkProps) {
  return (
    <Fragment>
      {href ? (
        <Link href={href} {...rest} className={className}>
          {children}
        </Link>
      ) : (
        children
      )}
    </Fragment>
  )
}

WrapperWithLink.defaultProps = {}

export default WrapperWithLink
